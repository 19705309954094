import ability from '@/libs/acl/ability'
import { initialAbility } from '@/libs/acl/config'
import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'

export default {
  namespaced: true,
  state: {
    errors: null,
    isAuthenticated: !!api.getToken(),
    configServer: {},
    user: {},
    userCurrentSession: {},
    toolboxLinks: [],
    locales: [
      {
        locale: 'pt',
        img: require('@/assets/images/flags/pt.png'),
        name: i18n.t('Português'),
      },
      {
        locale: 'es',
        img: require('@/assets/images/flags/es.png'),
        name: i18n.t('Espanhol'),
      },
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: i18n.t('Inglês'),
      },
    ],
  },
  getters: {
    currentUser: state => state.user,
    isAuthenticated: state => state.isAuthenticated,
    getConfigServer: state => state.configServer,
    currentUserSession: state => state.userCurrentSession,
    toolboxLinks: state => state.toolboxLinks,
    locales: state => state.locales,
    countryApp: state => {
      try {
        return state.userCurrentSession.country.toLowerCase() || 'pt'
      } catch (err) {
        return 'en'
      }
    },
  },
  mutations: {
    setError(state, error) {
      state.errors = error
    },
    setUser(state, data) {
      state.isAuthenticated = true
      state.user = data.infoUser
      state.user.id_role = Number(state.user.id_role)
      ability.update(data.infoUser.ability)

      this.commit('auth/i18nMergeUserSession')

      api.setToken(data.accessToken)
      api.setRefreshToken(data.refreshToken)
      api.setServiceToken(data?.serviceToken || '')
    },
    refreshInfoUser(state, data) {
      state.isAuthenticated = true
      state.user = data.infoUser
      state.user.id_role = Number(state.user.id_role)
      ability.update(data.infoUser.ability)

      this.commit('auth/i18nMergeUserSession')
    },
    logOut(state) {
      state.isAuthenticated = false
      state.user = {}
      state.toolboxLinks = []
      ability.update(initialAbility)
      api.destroyToken()

      setTimeout(() => {
        localStorage.clear()
      }, 800)
    },
    setConfigServer(state, config) {
      state.configServer = config || {}
    },
    refreshConfigServer(state) {
      try {
        if (state.userCurrentSession.country) {
          const { country } = state.configServer
          if (country) {
            state.configServer.urlAPP = country[state.userCurrentSession.country]
          }
        }
      } catch (err) {
        //
      }

      api.refreshConfigs(state.configServer)

      apiConfig.url_base_api = state.configServer.urlAPP
    },
    setUserSession(state, data) {
      const { infoUser } = data
      if (infoUser) {
        const oUserCurrentSession = {
          name: infoUser.name || '',
          email: infoUser.email || '',
          image: infoUser.image || '',
          country: infoUser.country || '',
        }
        localStorage.setItem('user', JSON.stringify(oUserCurrentSession))
      } else {
        localStorage.removeItem('user')
      }

      state.userCurrentSession = infoUser || {}
    },
    restoreUserSession(state) {
      let infoUser = null
      try {
        infoUser = JSON.parse(localStorage.getItem('user'))
      } catch {
        //
      }
      state.userCurrentSession = infoUser || null
    },
    i18nMergeUserSession(state) {
      if (state.user.nameHUB) {
        const txtMenuConsultores = i18n.t('Consultores')
        const txtMenuAngariacoes = i18n.t('Angariações')

        const newMessage = []

        if ([4].includes(Number(state.user.id_role)) && typeof state.user.extras.idLeader !== 'undefined') {
          newMessage['static.menuListingName'] = i18n.t('Do meu Líder')
        } else {
          newMessage['static.menuListingName'] = i18n.t('Minhas')
        }
        newMessage['static.menuListingNameHUB'] = state.user.nameHUB
        newMessage['static.menuShareAgentsNameHUB'] = `${txtMenuConsultores} ${state.user.nameHUB}`
        newMessage['static.menuShareListingNameHUB'] = `${txtMenuAngariacoes} ${state.user.nameHUB}`

        if (state.locales.length > 0) {
          state.locales.forEach(locale => {
            i18n.mergeLocaleMessage(locale.locale, newMessage)
          })
        }
      }
    },
    setToolboxLinks(state, payload) {
      state.toolboxLinks = payload || []

      if (state.toolboxLinks.length > 0) {
        const newMessage = []
        state.toolboxLinks.forEach(menu => {
          if (menu.sw047s02 !== '') {
            newMessage[menu.sw047s02] = menu.sw047s02
          }
        })

        Object.assign(state.toolboxLinks, state.toolboxLinks)

        if (state.locales.length > 0) {
          state.locales.forEach(locale => {
            i18n.mergeLocaleMessage(locale.locale, newMessage)
          })
        }
      }
    },
  },
  actions: {

    async getConfigServer(context) {
      await api.post(`${apiConfig.url_base_api}getConfigApp`).then(({ data }) => {
        const { config } = data
        context.commit('restoreUserSession')
        context.commit('setConfigServer', config)
        context.commit('refreshConfigServer')
      }).catch(() => {
        context.commit('restoreUserSession')
        context.commit('setConfigServer', {})
        context.commit('refreshConfigServer')
      })
    },

    async getInfoUserAuth({ state, commit, rootGetters }) {
      const idDevice = rootGetters['capacitor/idDevice']

      const formData = new FormData()
      formData.append('uuid', idDevice || '')
      formData.append('versionApp', process.env.VUE_APP_VERSION || '')

      const sTemplateAppCustomByUser = localStorage.getItem('template-app-custom-user')
      const sTemplateApp = localStorage.getItem('template-app')

      if (sTemplateAppCustomByUser !== null) {
        formData.append('skin', sTemplateApp || '')
      }

      if (state.isAuthenticated !== true) {
        await api.post(`${state.configServer.urlAPP}users/getInfo`, formData).then(({ data }) => {
          const { infoUser, toolbox, config } = data
          if (infoUser) {
            commit('setConfigServer', config)
            commit('refreshConfigServer')
            commit('setToolboxLinks', toolbox)
            commit('refreshInfoUser', data)
          } else {
            commit('logOut')
          }
        }).catch(() => {
          commit('logOut')
        })
      }
    },

    login(context, params) {
      return new Promise((resolve, reject) => {
        api.post(`${context.state.configServer.urlAPP}users/auth`, params)
          .then(({ data }) => {
            if (data.error === 1) {
              reject(data)
            } else {
              resolve(data)
            }
          })
          .catch(({ response }) => {
            context.commit('setError', response.data.errors)
          })
      })
    },

    authLockscreen(context, params) {
      return new Promise((resolve, reject) => {
        api.post(`${context.state.configServer.urlAPP}authLockscreen`, params)
          .then(({ data }) => {
            if (data.error === 1) {
              reject(data)
            } else {
              resolve(data)
            }
          })
          .catch(({ response }) => {
            context.commit('setError', response.data.errors)
          })
      })
    },

    loginUser(context, data) {
      context.commit('setUser', data)
      context.commit('setUserSession', data)
      context.commit('refreshConfigServer')

      if ((data !== null) && (data !== undefined) && ('toolbox' in data)) {
        context.commit('setToolboxLinks', data.toolbox)
      }
    },

    async logout(context) {
      await context.dispatch('request/cancelTokensPending', null, { root: true })
      // await context.dispatch('auth/registerPushNotification', { status: 1 }, { root: true })
      context.commit('logOut')
    },

    async checkAuth(context) {
      if (!!api.getToken() && !!context.state.userCurrentSession) {
        await context.dispatch('getInfoUserAuth')

        if (context.state.user.country !== undefined) {
          // Agora vamos obter o idioma por defeito do dispositivo
          // window.oVue.$i18n.locale = context.state.user.country.toLowerCase()
        }
      } else {
        context.commit('logOut')
        context.commit('setUserSession', {})
      }
    },

    forgotPassword(context, params) {
      return new Promise((resolve, reject) => {
        api.post(`${context.state.configServer.urlAPP}users/forgotPasswordRequestMobile`, params)
          .then(({ data }) => {
            if (data.error === 1) {
              reject(data)
            } else {
              resolve(data)
            }
          })
          .catch(({ response }) => {
            context.commit('setError', response.data.errors)
          })
      })
    },

    getChangePasswordValid(context, params) {
      return new Promise(resolve => {
        api.get(`${context.state.configServer.urlAPP}users/forgotPassword/${params.token}`)
          .then(({ data }) => {
            if ((data.data.changePasswordRecover === 1) && (data.data.swID !== '')) {
              resolve(data.data.swID)
            } else {
              resolve('')
            }
          })
          .catch(() => {
            resolve('')
          })
      })
    },

    changeForgotPasswordRequest(context, params) {
      return new Promise((resolve, reject) => {
        api.post(`${context.state.configServer.urlAPP}users/changeForgotPasswordRequest`, params)
          .then(({ data }) => {
            if (data.error === 1) {
              reject(data)
            } else {
              resolve(data)
            }
          })
          .catch(({ response }) => {
            context.commit('setError', response.data.errors)
          })
      })
    },

    async registerPushNotification({ rootGetters, dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'notification/setDeviceNotificationPush', { root: true })

      return new Promise(resolve => {
        const { model, platform, webViewVersion } = rootGetters['capacitor/infoDevice']
        const idDevice = rootGetters['capacitor/idDevice']
        const formData = new FormData()
        formData.append('model', model || '')

        if (platform.toLowerCase() === 'android') {
          formData.append('platform', 'Android')
        }

        if (platform.toLowerCase() === 'ios') {
          formData.append('platform', 'iOS')
        }

        formData.append('uuid', idDevice || '')
        formData.append('version', webViewVersion || '')
        formData.append('key_device', localStorage.getItem('push-notification-token') || '')
        formData.append('status', payload.status || '')

        api.post(`${apiConfig.url_base_api}notification/setDeviceNotificationPush`, formData)
          .then(({ data }) => {
            if (data.error === 1) {
              resolve(false)
            } else {
              resolve(true)
            }
          }).catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              resolve(false)
            }
          })
      })
    },

    async getStatusDeviceNotificationPush({ rootGetters, dispatch }) {
      await dispatch('request/cancelTokensPendingByUrl', 'notification/getStatusDeviceNotificationPush', { root: true })

      return new Promise(resolve => {
        const { platform } = rootGetters['capacitor/infoDevice']
        const idDevice = rootGetters['capacitor/idDevice']
        const formData = new FormData()
        formData.append('platform', platform || '')
        formData.append('uuid', idDevice || '')

        api.post(`${apiConfig.url_base_api}notification/getStatusDeviceNotificationPush`, formData)
          .then(({ data }) => {
            resolve({
              status: data.status,
              existConfig: data.existConfig,
            })
          }).catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              resolve({ status: 0, existConfig: 0 })
            }
          })
      })
    },

    async setConfigAccountUser(state, payload) {
      const formData = new FormData()
      formData.append('locale', payload.locale || '')
      formData.append('skin', payload.skin || '')
      await api.post(`${apiConfig.url_base_api}users/setConfigAccountUser`, formData)
    },

    async getPreferenceByUserAuth({ state }) {
      return new Promise(resolve => {
        const preferenceUserApp = { locale: '', skin: '' }

        if (!!api.getToken() && !!state.userCurrentSession) {
          api.post(`${state.configServer.urlAPP}users/getInfo`).then(({ data }) => {
            const { infoUser } = data
            if (infoUser) {
              if ('preferenceMobile' in infoUser) {
                if (('locale' in infoUser.preferenceMobile) && (infoUser.preferenceMobile.locale !== '')) {
                  preferenceUserApp.locale = infoUser.preferenceMobile.locale
                }

                if (('skin' in infoUser.preferenceMobile) && (infoUser.preferenceMobile.skin !== '')) {
                  preferenceUserApp.skin = infoUser.preferenceMobile.skin
                }
              }
            }

            resolve(preferenceUserApp)
          }).catch(() => {
            resolve(preferenceUserApp)
          })
        } else {
          resolve(preferenceUserApp)
        }
      })
    },

  },
}
